import React, {useMemo} from 'react';
import {FormLayout} from '@shopify/polaris';
import TextSettingsBlock from './components/textSettingsBlock';
import {TEXT_SETTINGS_BLOCKS} from '../../../../../constants/constants';

const TextTab = (props) => {
  const {state} = props;

  const renderBlocks = useMemo(() => {
    return TEXT_SETTINGS_BLOCKS.map(({heading, blockType}, index) => {
      return <TextSettingsBlock key={index} heading={heading} blockType={blockType} {...props}/>
    });
  }, [state]);

  return (
    <FormLayout>
      {renderBlocks}
    </FormLayout>
  );
};

export default TextTab;